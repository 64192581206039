<template>
  <div
    v-loading="isLoading"
    class="RequestRow tw-grid tw-grid-cols-12 tw-gap-x-4 tw-gap-y-2"
    :class="{
      'RequestRow--emergency': props.request.type_id === $store.state.requests.EMERGENCY_TYPE_ID,
      'RequestRow--organization': props.request.organization,
      'serviceA': props.request.service_id === 1,
      'serviceB': props.request.service_id === 2,
      'shadow-pink': !props.quickPreview,
    }"
  >
    <div
      class="tw-col-span-12 RequestDesc tw-mb-4 md:tw-mb-0"
      :class="props.quickPreview ? 'md:tw-col-span-5' : 'md:tw-col-span-4'"
    >
      <ServiceIconXl
        :service-id="props.request.service_id"
        class="tw-mr-5"
      />
      <div>
        <div class="Label tw-flex tw-items-center">
          <OperatorRequestRowClient
            v-if="props.type !== 'history'"
            :client="props.request.client"
            :request="props.request"
          />
          <OperatorRequestRowType
            v-else
            :type-id="props.request.type_id"
            :client="props.request.client"
          />

          <strong
            v-if="props.type === 'history' && props.request.service_id === 2"
          >
            Přepis
          </strong>

          <DeviceIcon
            :type="props.request.device"
            class="color-muted tw-ml-2"
          />
          <el-tooltip
            v-if="props.request.bad_connection"
            content="Pomalé internetové připojení"
            placement="top"
          >
            <icon-bad-connection class="tw-text-danger-500 tw-ml-2" />
          </el-tooltip>
        </div>
        <div
          class="RequestRowCreatedAt tw-text-sm tw-text-text-light"
          data-testid="created-at"
        >
          {{ dayjs(props.request.created_at).format('l, LT') }}
        </div>
      </div>
    </div>
    <div
      class="tw-col-span-12 tw-grid tw-grid-cols-12 tw-px-5 md:tw-px-0"
      :class="`${props.quickPreview ? 'md:tw-col-span-7' : 'md:tw-col-span-8'}`"
    >
      <div
        v-if="props.type !== 'history'"
        :class="`tw-col-span-6 ${props.type === 'history-all' ? 'md:tw-col-span-3' : 'md:tw-col-span-4'}`"
      >
        <OperatorRequestRowType
          v-if="props.request.service_id !== 2"
          :type-id="props.request.type_id"
          :client="props.request.client"
        />
      </div>
      <div
        class="tw-col-span-6 tw-flex tw-flex-col tw-justify-center"
        :class="props.type === 'history' ? 'md:tw-col-span-5' : (props.quickPreview ? '' : 'md:tw-col-span-4')"
        data-testid="state-and-book-time"
      >
        <template v-if="props.request.state_id === 1">
          <template v-if="props.request.has_book_time">
            <template v-if="dayjs(now).isBefore(props.request.book_time)">
              <div class="Label tw-font-semibold tw-text-primary-500">
                Rezervace
              </div>
              <div class="tw-text-text-light tw-text-sm">
                {{ dayjs(props.request.book_time).format('l, LT') }}
              </div>
            </template>
            <template v-else>
              <div
                class="Label tw-font-semibold tw-text-danger-500"
              >
                Čeká
                <strong class="tw-font-semibold">
                  {{ timeDifference(now, props.request.book_time, 'minute', true) }} min
                </strong>
              </div>
              <div
                class="tw-text-text-light tw-text-sm"
              >
                Rezervace
                {{ dayjs(props.request.book_time).format('l, LT') }}
              </div>
            </template>
          </template>

          <template v-else>
            <div
              class="Label"
              :class="{ 'tw-text-danger-500': props.request.type_id === $store.state.requests.EMERGENCY_TYPE_ID }"
            >
              Čeká
              <strong class="tw-font-semibold">
                {{ timeDifference(now, props.request.book_time, 'minute', true) }} min
              </strong>
            </div>
            <div
              v-if="props.request.type_id !== $store.state.requests.EMERGENCY_TYPE_ID"
              class="tw-text-text-light tw-text-sm"
            >
              bez rezervace
            </div>
          </template>
        </template>

        <div
          v-if="props.request.state_id === 4 || props.request.state_id === 5"
          class="tw-flex tw-flex-col tw-mr-4"
        >
          <strong
            v-if="props.request.state_id === 4"
            class="tw-mb-1"
          >
            Uzavřeno
          </strong>
          <strong
            v-else-if="props.request.state_id === 5"
            class="tw-text-danger-500 tw-mb-1"
          >
            Zrušeno
          </strong>
          <span
            v-if="props.request.finalized_at"
            class="tw-text-text-light"
          >
            {{ dayjs(props.request.finalized_at).format('DD. MM. YYYY, HH:mm') }}
          </span>
        </div>
      </div>
      <div
        class="requestActions tw-col-span-12 tw-flex tw-justify-center md:tw-justify-end tw-items-center tw-mt-4 md:tw-mt-0 md:tw-mb-0 md:tw-pr-5"
        :class="props.quickPreview ? 'md:tw-col-span-2' : (props.type === 'history' ? 'md:tw-col-span-7' : (props.type === 'history-all' ? 'md:tw-col-span-5' : 'md:tw-col-span-4'))"
        data-testid="actions"
      >
        <div
          v-if="props.request.state_id !== 1 && props.request.operator"
          class="tw-flex tw-flex-col tw-mr-auto tw-ml-0"
        >
          <strong
            v-if="(props.request.state_id === 2 || props.request.state_id === 3) && (props.request.operator.id !== useAuthStore()?.user?.id)"
            class="tw-text-primary-500 tw-mb-1"
          >
            Vyřizuje
          </strong>
          <strong
            v-if="props.request.state_id === 4 || props.request.state_id === 5"
            class="tw-mb-1"
          >
            Vyřídil
          </strong>
          <span
            v-if="(props.request.operator.id !== useAuthStore()?.user?.id || (props.request.state_id === 4 || props.request.state_id === 5))"
            class="tw-text-text-light"
          >
            {{ props.request.operator.first_name }} {{ props.request.operator.last_name }}
          </span>
        </div>

        <template v-if="!props.quickPreview">
          <template v-if="props.request.operator && props.request.operator.id === useAuthStore()?.user?.id">
            <el-tooltip
              v-if="props.request.state_id === 2 || props.request.state_id === 3"
              content="Uzavřít požadavek"
              placement="top"
            >
              <el-button
                type="primary"
                class="tw-ml-4"
                plain
                circle
                data-testid="finish-request"
                @click="updateRequestState(props.request.id, 4)"
              >
                <icon-flag class="tw-text-primary-500" />
              </el-button>
            </el-tooltip>
            <el-tooltip
              v-if="props.request.state_id === 3"
              content="Pokračovat"
              placement="top"
            >
              <el-button
                type="primary"
                class="tw-ml-4"
                plain
                circle
                @click="updateRequestState(props.request.id, 2)"
              >
                <icon-play class="tw-text-primary-500" />
              </el-button>
            </el-tooltip>
            <el-tooltip
              v-if="props.request.state_id === 2 || props.request.state_id === 3"
              content="Předat požadavek"
              placement="top"
            >
              <el-button
                class="tw-ml-4"
                plain
                circle
                data-testid="forward-request"
                @click="useDialogStore().update({ dialogName: 'requestForward', data: { visible: true, id: props.request.id, service_id: props.request.service_id } })"
              >
                <icon-transfer class="tw-h-auto" />
              </el-button>
            </el-tooltip>
          </template>

          <el-tooltip
            v-if="props.request.state_id === 1 && props.request.client?.lang === useAuthStore()?.user?.lang"
            content="Přijmout požadavek"
            placement="top"
          >
            <el-button
              type="primary"
              class="tw-ml-4"
              plain
              circle
              data-testid="accept-request"
              @click="acceptRequest"
            >
              <icon-check class="tw-text-primary-500 tw-h-auto" />
            </el-button>
          </el-tooltip>
          <template v-if="!props.request.operator || props.request.operator.id === useAuthStore()?.user?.id || props.request.state_id === 4">
            <el-tooltip
              content="Chat s klientem"
              placement="top"
            >
              <nuxt-link
                :to="`/operator/requests/${props.request.id}/chat`"
                class="tw-ml-4"
                data-testid="open-chat"
              >
                <el-button
                  type="primary"
                  plain
                  circle
                >
                  <icon-chat class="color-muted" />
                </el-button>
              </nuxt-link>
            </el-tooltip>
          </template>
        </template>
        <el-tooltip
          :content="props.quickPreview ? 'Otevřít v nové kartě' : 'Zobrazit detail'"
          placement="top"
        >
          <nuxt-link
            :to="`/operator/requests/${props.request.id}/detail`"
            :target="props.quickPreview ? '_blank' : undefined"
            class="tw-ml-4"
            data-testid="open-detail"
          >
            <el-button
              type="primary"
              plain
              circle
            >
              <component
                :is="props.quickPreview ? 'icon-leave' : 'icon-right'"
                class="color-muted"
              />
            </el-button>
          </nuxt-link>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  request: {
    type: Object,
    required: true,
  },
  type: {
    type: String,
    default: null,
  },
  quickPreview: {
    type: Boolean,
    default: false,
  },
});

// Refs
const { isLoading, updateRequestState } = useRequestState();
const now = useNow();

// Methods
const acceptRequest = () => {
  if (props.request?.client?.lang === 'ua' && useAuthStore()?.user?.lang !== 'ua') {
    useDialogStore().open('confirmRequestAccept');
    useDialogStore().update({
      dialogName: 'confirmRequestAccept',
      data: {
        title: 'Ukrajinský požadavek',
        text: 'Opravdu chcete přijmout ukrajinský požadavek?',
        acceptText: 'Přijmout požadavek',
        accept: () => {
          useDialogStore().close('confirmRequestAccept');
          updateRequestState(props.request.id, 2);
        },
      },
    });
  } else if (props.request.has_book_time) {
    useDialogStore().open('confirmRequestAccept');
    useDialogStore().update({
      dialogName: 'confirmRequestAccept',
      data: {
        title: 'Požadavek s rezervací',
        text: 'Opravdu chcete přijmout požadavek s rezervací?',
        acceptText: 'Přijmout požadavek',
        accept: () => {
          useDialogStore().close('confirmRequestAccept');
          updateRequestState(props.request.id, 2);
        },
      },
    });
  } else {
    updateRequestState(props.request.id, 2);
  }
};
</script>

<style scoped>
.RequestRow {
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 8px;
  border-radius: 2px;
  padding-top: 20px;
  padding-bottom: 20px;

  @media (max-width: theme('screens.sm')) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @apply tw-bg-white;

  &--emergency {
    border-left: 4px solid theme('colors.danger.500');
  }

  &--organization {
    &.serviceA {
      border-left: 4px solid theme('colors.secondary.500');
    }

    &.serviceB {
      border-left: 4px solid theme('colors.tertiary.500');
    }
  }

  .Label {
    &:not(:only-child) {
      margin-bottom: 2px;
    }
  }

  .RequestDesc {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 5px;
  }
}
</style>
